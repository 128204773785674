import { createSignal, Show } from "solid-js";
import { MenuIcon } from "../icons"
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { ShowHeading } from '../SmallComponents/SmallHeader' 

import { Menu, MenuItem, Switch, Button, Typography, Grid,Card, Stack, Divider, MenuList, FormControlLabel,TextField, DialogActions, Box} from "@suid/material";
import { getText } from '../SmallComponents/Translate'

const ShowMenu = () => {
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
   
    const openMenu = () => !!anchorEl();
   
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        openMenu()
    };

    const menuItems = [
        { text: "Local action menu 1", switch: true, checked: true, },
        { text: "Local action menu 2", disabled: true, },
    ];

    return (
        <>
            <Button size="small" color="primary" variant="text" onClick={handleClickMenuListItem}>  <MenuIcon /> </Button>
            {openMenu() && (
                <Menu anchorEl={anchorEl()} open={openMenu()} onClose={handleMenuClose}>
                    <MenuList >
                        {menuItems.map((item, index) => (
                            <MenuItem value={index} disabled={item.disabled}>
                                {item.switch && <Switch id="autoconfirmedEnabled" defaultChecked={item.checked} />}
                                {item.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            )}
        </>

    );
}
 
  
// candidateAcceptInterviewInvite: false,
 
// candidateAcceptPing: false,
// candidateDeclinePingInvite: false,
// candidateDeclineMatchedJob: false,
// candidateDeclineInterviewInvite: false,
 
// candidateRateCoWorker: false,
// candidateAskCoWorkerForRating: false,
// candidateShareJob: false,
// candidatePutOnGreylist: false,
 
interface CandidateWFFeedbackComponentProps {
    header: string;
    wfCandidateType: "candidateSendIAmInterested" | "candidateSendPositiveFeedback" | "candidateSendNegativeFeedback" | "candidateAcceptProposal"  | 
        "candidateSendCounterProposal" | "candidateAcceptContract" | "candidateSignContract"| "terminateContract"| "askForContractExtension"
}

const CandidateWFFeedbackComponent = (props: CandidateWFFeedbackComponentProps) => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    
    const handleClose = () => {
        alert("Close CandidateWFFeedbackComponent")
        setOpen(false)
        window.history.go(-1)
    }
   
    const [textMessage, setTextMessage] = createSignal("");

    const txtSendContract = getText("workflowpage", "sendcontract");
    const txtSendPositiveFeedback = getText("workflowpage", "sendpositivefeedback");
    const txtSendNegativeFeedback = getText("workflowpage", "sendnegativefeedback");
    const txtSend = getText("workflowpage", "send");
    const txtSendPositiveFeedbackTo = getText("workflowpage", "sendpositivefeedbackto", [{ name: "Name", value: props.header }]);
    const txtSendNegativeFeedbackTo = getText("workflowpage", "sendnegativefeedbackto", [{ name: "Name", value: props.header }]);

    const txtExternalMessage = getText("workflowpage", "externalmessage");
    const txtClose = getText("default", "close");
    const txtOptions = getText("workflowpage", "options");
    const txtGreylistCandidate = getText("workflowpage", "greylistcandidate");
    const txtRateCandidate = getText("workflowpage", "ratecandidate");
    const txtTechnicalCompetence = getText("workflowpage", "technicalcompetence");
    const txtSocialCompetence = getText("workflowpage", "socialcompetence");
    const txtWorkTogetherAgain = getText("workflowpage", "worktogetheragain");

    const getButtonActionText = (type: string) => {
        if (type === "positiveFeedback")
            return txtSendPositiveFeedback()
        else if (type === "negativeFeedback")
            return txtSendNegativeFeedback()
        else
            return txtSend()
    }

    return (

        <>
            {/* ********************** */}
            {/* Show button in wf bar */}
            {/* ********************** */}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <Box sx={{ margin: 2, width: 1000, bgcolor: 'background.default'[200] }}>
                    <Card>

                        <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ display: 'flex' }} >
                            {/* Addition navigation options  */}
                            <Box sx={{ margin: 1, display: 'flex', }} >

                                <ShowMenu />
                                <Show when={props.wfCandidateType === "candidateSendPositiveFeedback"}
                                    fallback={
                                        <ShowHeading >{txtSendNegativeFeedbackTo()}</ShowHeading>
                                    }
                                >
                                    <ShowHeading>{txtSendPositiveFeedbackTo()}</ShowHeading>
                                </Show>
                            </Box>
                        </Stack>

                        <Divider />

                        <Card sx={{ margin: 1, display: 'flex' }}>

                            <Grid container spacing={2}>



                                {/* ***************************** */}
                                {/* Preview message section       */}
                                {/* ***************************** */}

                                <Grid item xs={12}  >

                                    <Card sx={{ backgroundColor: 'background.default'[50], flexGrow: 1, margin: 1 }} >
                                        <Stack direction="column" margin={1} spacing={2}>

                                            {/* Message*/}
                                            <CardWithMinimize header={txtExternalMessage() as string} type="list" >
                                                <TextField
                                                    required
                                                    fullWidth={true}
                                                    sx={{ width: "100%" }}
                                                    id="textarea-input"
                                                    multiline
                                                    rows={4}
                                                    autoComplete="text"
                                                    variant="outlined"
                                                    size="small"
                                                    value={textMessage()}
                                                    onChange={(event) => {
                                                        setTextMessage(event.target.value);
                                                    }}
                                                />
                                            </CardWithMinimize>


                                            {/*  Typography color="text.secondary" innerHTML={previewTextMessage().replace(/\n/g, '<br>')} > */}
                                            <Show when={props.wfCandidateType  === "candidateSendPositiveFeedback"}
                                                fallback={
                                                    <>  <Typography color="text.secondary" p={0.5} >
                                                        Hi Michael,<br />

                                                        Thanks for taking your time to discuss the potentiel assignment. We are sorry to inform you that we have offered the assignment to another candidate.  <Show
                                                            when={textMessage()}
                                                            fallback={
                                                                <>
                                                                    <br /><br />
                                                                </>
                                                            }
                                                        >
                                                            <br />
                                                            {textMessage()}
                                                            <br /><br />
                                                        </Show>

                                                        <br />
                                                        We hope we can offer your a position next time.
                                                        <br />
                                                        <b>Best regards,</b><br />
                                                        Lion Messia
                                                    </Typography>
                                                    </>
                                                }
                                            >
                                                <Typography color="text.secondary" p={0.5} >
                                                    Hi Sandy,<br />

                                                    Thanks for taking your time to discuss the potentiel assignment. I would very much like to join your project. I think I have much to contribute with and hope you would take me into consideration.
 
                                                    <Show
                                                        when={textMessage()}
                                                        fallback={
                                                            <>
                                                                <br /><br />
                                                            </>
                                                        }
                                                    >
                                                        <br />
                                                        {textMessage()}
                                                        <br /><br />
                                                    </Show>

                                                    <br />
                                                    Look forward to hearing from you.
                                                    <br />
                                                    <b>Best regards,</b><br />
                                                    Michael Scott
                                                </Typography>
                                            </Show>
                                        </Stack>

                                    </Card>
                                </Grid>
                            </Grid>

                        </Card>

                        <DialogActions>

                            <Button variant="outlined" onClick={handleClose}>
                                {txtClose()}
                            </Button>
                            <Button variant="contained">
                                {getButtonActionText(props.wfCandidateType)}
                            </Button>

                        </DialogActions>
                    </Card>
                </Box>
            </Box>
        </>
    );
}
export { CandidateWFFeedbackComponent }