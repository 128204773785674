import { createSignal, Switch as SolidSwitch, Match, JSX, For } from "solid-js";
import { CustomerTemplatePage } from './CustomerTemplatePage'

import { CustomerWFTemplatePage } from '../components/Workflow/CustomerWFTemplatePage'
import { CustomerWFInterviewComponent } from '../components/Workflow/CustomerWfInterviewComponent'
import { CandidateWFFeedbackComponent } from '../components/Workflow/CandidateWfFeedbackComponent'
import { CustomerWFContractSignComponent } from '../components/Workflow/CustomerWfContractSignComponent'
import { CustomerWFContractTerminateComponent } from '../components/Workflow/CustomerWfContractTerminateComponent'
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'
import { useParams } from "@solidjs/router";

const ShowCandidateWorkflowPage = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }
    const params = useParams();  

    const [selectedWorkflow, setSelectedWorkflow] = createSignal(params.name ? params.name : 'candidateSendPositiveFeedback');
    const [selectedId, setSelectedId] = createSignal(params.id ? params.id : '0');

    const txtInterview = getText("workflowpage", "interview")
    const txtPositiveFeedback = getText("workflowpage", "positivefeedback")
    const txtNegativeFeedback = getText("workflowpage", "negativefeedback")
    const txtProposal = getText("workflowpage", "proposal")
    const txtContract = getText("workflowpage", "contract")
    const txtSignContract = getText("workflowpage", "signcontract")
    const txtExtendContract = getText("workflowpage", "extendcontract")
    const txtTerminateContract = getText("workflowpage", "terminatecontract")
    const txtGreyList = getText("workflowpage", "greylist")
    const txtPingCandidate = getText("workflowpage", "pingcandidate")
    const txtHeader = getText("workflowpage", "header")
    const txtError = getText("workflowpage", "error")
    const txtWfHeader = "Coca Cola, Project Manager, S/4 Upgrade for "+selectedId()

    type selectedWorkflowType = 
    "candidateSendIAmInterested" | "candidateSendPositiveFeedback" | "candidateSendNegativeFeedback" | "candidateAcceptProposal"  | 
    "candidateSendCounterProposal" | "candidateAcceptContract" | "candidateSignContract"| "candidateTerminateContract"| "candidateAskForContractExtension" | 
    "candidateAcceptInterview"; 

    interface ComponentMapping {
        component: JSX.Element;
        types: selectedWorkflowType[];
    }

    const componentMappings: ComponentMapping[] = [
        { component: <CustomerWFInterviewComponent type="interview" header={txtWfHeader} />, types: ["candidateAcceptInterview"] },
        { component: <CandidateWFFeedbackComponent wfCandidateType="candidateSendPositiveFeedback" header={txtWfHeader} />, types: ["candidateSendPositiveFeedback"] },
        { component: <CandidateWFFeedbackComponent wfCandidateType="candidateSendNegativeFeedback" header={txtWfHeader} />, types: ["candidateSendNegativeFeedback"] },
        { component: <CustomerWFTemplatePage type={selectedWorkflow()} header={txtWfHeader} />, types: ["candidateSendIAmInterested", "candidateAskForContractExtension"] },
        { component: <CustomerWFContractSignComponent type={selectedWorkflow()} header={txtWfHeader} />, types: ["candidateAcceptContract","candidateSignContract"] },
        { component: <CustomerWFContractTerminateComponent type={selectedWorkflow()} header={txtWfHeader} />, types: ["candidateTerminateContract"] }
    ];

    const getMainComponentForselectedWorkflow = (button: selectedWorkflowType) => {
        return componentMappings.find(mapping => mapping.types.includes(button))?.component || null;
    }

    return (
        <>
            <SolidSwitch fallback={<div>{selectedWorkflow()} {txtError()}</div>}>

                <For each={componentMappings}
                >
                    {mapping =>
                        <Match when={mapping.types.includes(selectedWorkflow() as selectedWorkflowType)}>
                            <CustomerTemplatePage
                                type="candidate"
                                leftPaneComponent={null}
                                mainComponent={getMainComponentForselectedWorkflow(selectedWorkflow() as selectedWorkflowType)}
                                buttonComponent={null}>
                                {txtHeader()}
                            </CustomerTemplatePage>
                        </Match>
                    }
                </For>
            </SolidSwitch>
        </>
    );
}
export { ShowCandidateWorkflowPage }